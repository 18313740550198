import request from "@/utils/request";
import Export from "@/utils/export";
// 基本信息
export function baseInfo(data) {
  return request("post", "/aps/dw/basic/information", data);
}
// 用户和会员统计
export function memberInfo(data) {
  return request("post", "/aps/dw/user/member", data);
}
// 用户会员当周数据
export function weekMemberInfo(data) {
  return request("post", "/aps/dw/user/member/current/data", data);
}
// 营收汇总统计
export function saleInfo(data) {
  return request("post", "/aps/dw/revenue/statics/data", data);
}
//   查询业态服务内容树
export function serviceTree(data) {
  return request("post", "/aps/dw/template/tree", data);
}
// 营收当周数据-统计
export function weekSaleInfo(data) {
  return request("post", "/aps/dw/revenue/current/data", data);
}
// 营收当周数据-订单
export function weekOrderInfo(data) {
  return request("post", "/aps/dw/order/current/data", data);
}
// 包厢集合统计-当周数据
export function weekRoomInfo(data) {
  return request("post", "/aps/dw/service/current/data", data);
}
//   获取最受欢迎包厢
export function populartyRoom(data) {
  return request("post", "/aps/dw/get/most/popular", data);
}
//   营收最高包厢
export function richRoom(data) {
  return request("post", "/aps/dw/get/highest/revenue", data);
}
//   会员趋势分析
export function memberTrend(data) {
  return request("post", "/aps/dw/get/member/trend", data);
}
//   营收趋势分析
export function saleTrend(data) {
  return request("post", "/aps/dw/get/revenue/trend", data);
}
//   营收分析-包厢营收明细
export function revenueDetail(data) {
  return request("post", "/aps/dw/get/room/revenue/details", data);
}

