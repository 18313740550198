<template>
    <div class="lineChart">
        <div id="rechargeChart" ref="rechargeChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["chartName", "merchantShopInfo", 'echartData', 'type'],
    data() {
        return {
            myEchart: "",
            legendData: [],
            seriesData: [],
        };
    },
    computed: {
        // 日期
        dateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.snapDay];
                })
            }
            return arr;
        },
    },
    methods: {
        handleData() {
            const result = [];
            const serviceMap = new Map();
            if (this.echartData && this.echartData.length > 0) {
                if (this.type == 1) {
                    this.legendData = this.echartData[0].serTotalRList.map(item => item.serviceName);
                    // 遍历原始数组
                    this.echartData.forEach(entry => {
                        entry.serTotalRList.forEach(service => {
                            const { serviceName, totalRevenue } = service;
                            if (!serviceMap.has(serviceName)) {
                                serviceMap.set(serviceName, []);
                            }
                            serviceMap.get(serviceName).push(Number(totalRevenue)); // 将收入转为数字类型
                        });
                    });
                } else {
                    this.legendData = this.echartData[0].serAvgList.map(item => item.serviceName);
                    // 遍历原始数组
                    this.echartData.forEach(entry => {
                        entry.serAvgList.forEach(service => {
                            const { serviceName, dailyRAvg } = service;
                            if (!serviceMap.has(serviceName)) {
                                serviceMap.set(serviceName, []);
                            }
                            serviceMap.get(serviceName).push(Number(dailyRAvg)); // 将收入转为数字类型
                        });
                    });
                }

                // 构建新数组并添加固定属性
                serviceMap.forEach((data, name) => {
                    result.push({
                        name,
                        data,
                        type: "line",  // 固定值
                        smooth: true   // 固定值
                    });
                });
                this.seriesData = result;
            }else{
                this.legendData = [];
                this.seriesData = [];
            }
        },
        // 【渲染】折线图
        myChartFn() {
            this.$nextTick(() => {
                this.handleData()
                if (this.myEchart == "") {
                    this.myEchart = echarts.init(this.$refs.rechargeChart);
                }
                let option = {
                    grid: {
                        top: '30%',
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: this.dateList,
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    yAxis: {
                        type: "value",
                        name: '金额（元）',
                    },
                    legend: {
                        data: this.legendData,
                        top: 0,
                        height: 200,

                    },
                    series: this.seriesData,
                };
                this.myEchart.setOption(option,true);
            })
        },

    },
};
</script>

<style lang="scss" scoped>
.lineChart {
    width: 6.74rem;
    height: 100%;
}
</style>