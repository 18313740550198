<template>
    <div class="note">
                <p>统计范围：</p>
                <el-cascader v-model="selectedService" :options="options" :props="{ checkStrictly: true, multiple: true }"
                    disabled placeholder="全部范围"></el-cascader>
            </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'App',
  data() {
    return {
      // Your data properties go here
    };
  },
  computed: {
			...mapState(['selectedService','options'])
		},
  methods: {
    // Your methods go here
  }
};
</script>

<style scoped lang="scss">
/* Your CSS styles go here */
.note {
    display: flex;
    align-items: center;
    padding-left: 0.2rem;
    p {
        width: 1rem;
    }
    margin-bottom: 0.2rem;
    background: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #979797;
}
</style>