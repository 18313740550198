<template>
    <div class="lineChart">
        <div id="vipChart" ref="vipChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["chartName", "merchantShopInfo",'echartData'],
    data() {
        return {
            vipChart: "",         
        };
    },
    computed: {
         // 日期
         dateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.snapDay];
                })
            }
            console.log(arr);
            return arr;
        },
        // 包厢日均坪效
        roomDAAvgList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.roomDAAvg];
                })
            }
            return arr;
        },
        // 包厢日均
        roomAvgList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.roomAvg];
                })
            }
            return arr;
        }
    },
    methods: {
        // 【渲染】折线图
        myChartFn() {
            if (this.vipChart == "") {
                this.vipChart = echarts.init(this.$refs.vipChart);
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
        
                legend: {
                    data: ['包厢日均坪效', '包厢日均']
                },
                xAxis: [
                    {
                        type: 'category',
                        data:this.dateList,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '坪效（元/平方/天）',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                    {
                        type: 'value',
                        name: '日均（元/天）',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                series: [
                    {
                        name: '包厢日均坪效',
                        type: 'line',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元/平方/天';
                            }
                        },
                        data:this.roomDAAvgList
                    },
                   
                    {
                        name: '包厢日均',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元/天';
                            }
                        },
                        data:this.roomAvgList
                    }
                ]
            };
            this.vipChart.setOption(option);
        },


        // 【请求】收款方式项趋势分析
        getPayChannelLine() {
            if (this.dateToDate) {
                this.tableParams.searchStartTime = this.dateToDate[0];
                this.tableParams.searchEndTime = this.dateToDate[1];
            } else {
                this.tableParams.searchStartTime = "";
                this.tableParams.searchEndTime = "";
            }
            let data = this.tableParams;
            getPayChannelLine(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.chartInfo = res.data;
                    this.$nextTick(() => {
                        this.myChartFn();
                    });
                }
            });
        }




    },
};
</script>

<style lang="scss" scoped>
.lineChart {
    width: 6.74rem;
    height: 3.3rem;
}
</style>