<template>
    <div class="lineChart">
        <div id="rechargeChart" ref="rechargeChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["chartName", "merchantShopInfo",'echartData'],
    data() {
        return {
            myEchart: "",
        };
    },
    computed: {
        // 日期
        dateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.snapDay];
                })
            }
            return arr;
        },
        // 订单数量
        orderCountList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.orderCount];
                })
            }
            return arr;
        },
        // 续单数量
        conCountList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.conCount];
                })
            }
            return arr;
        }
    },
    methods: {
        // 【渲染】折线图
        myChartFn() {
            if (this.myEchart == "") {
                this.myEchart = echarts.init(this.$refs.rechargeChart);
            }
            let option = {
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.dateList,
                },
                tooltip: {
                    trigger: "axis",
                },
                color: [
                    "#EF9807",
                    "#6DBE00",
                ],
                yAxis: {
                    type: "value",
                    name: '数量（个）',
                },
                legend: {
                    data: [
                        "订单数量",
                        "续单数量",
                    ],
                },
                series: [
                    {
                        name: "订单数量",
                        data: this.orderCountList,
                        type: "line",
                        smooth: true,
                    },
                    {
                        name: "续单数量",
                        data: this.conCountList,
                        type: "line",
                        smooth: true,
                    }
                   
                ],
            };
            this.myEchart.setOption(option);
        }
    },
};
</script>

<style lang="scss" scoped>
.lineChart {
    width: 6.74rem;
    height: 3.3rem;
}
</style>