<template>
    <div class="lineChart">
        <div id="rechargeChart" ref="rechargeChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["chartName", "merchantShopInfo",'echartData'],
    data() {
        return {
            myEchart: "",
        };
    },
    computed: {
        // 日期
        dateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.snapDay];
                })
            }
            return arr;
        },
        // 团购收入
        grIncomeList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.grIncome];
                })
            }
            return arr;
        },
        // 微信收入
        wechatIncomeList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.wechatIncome];
                })
            }
            return arr;
        },
        // 线下收入
        beIncomeList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.beIncome];
                })
            }
            return arr;
        },
        // 本金收入
        prIncomeList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.prIncome];
                })
            }
            return arr;
        }
    },
    methods: {
        // 【渲染】折线图
        myChartFn() {
            if (this.myEchart == "") {
                this.myEchart = echarts.init(this.$refs.rechargeChart);
            }
            let option = {
                grid: {
                    top: '30%',
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.dateList,
                },
                tooltip: {
                    trigger: "axis",
                },
                color: [
                    "#EF9807",
                    "#6DBE00",
                    "#4870FF",
                    "#B678F5"
                ],
                yAxis: {
                    type: "value",
                    name: '金额（元）',
                },
                legend: {
                    data: [
                        "微信收入",
                        "线下收入",
                        "团购收入",
                        "本金收入"
                    ],
                },
                series: [
                    {
                        name: "微信收入",
                        data: this.wechatIncomeList,
                        type: "line",
                        smooth: true,
                    },
                    {
                        name: "线下收入",
                        data: this.beIncomeList,
                        type: "line",
                        smooth: true,
                    },
                    {
                        name: "团购收入",
                        data: this.grIncomeList,
                        type: "line",
                        smooth: true,
                    },
                    {
                        name: "本金收入",
                        data: this.prIncomeList,
                        type: "line",
                        smooth: true,
                    }
                ],
            };
            this.myEchart.setOption(option);
        }
    },
};
</script>

<style lang="scss" scoped>
.lineChart {
    width: 6.74rem;
    height: 100%;
}
</style>