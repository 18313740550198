<template>
    <div class="page">
        <div class="top">
            <div style="display: flex;align-items: center;">
                <div class="subtitle">当周数据</div>
                <el-cascader v-model="value" :options="options" @change="handleChange" :props="{ multiple: true }"
                    clearable></el-cascader>
            </div>
            <div class="btn" @click="onChart">查看趋势分析<i class="el-icon-arrow-right"></i> </div>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :title="chartName" :visible.sync="editShow" :close-on-click-modal="false" append-to-body
            width="80%" center z-index="1000">
            <DataRange />
            <el-tabs class="tabs" v-model="currentMenu" @tab-click="onLink">
                <el-tab-pane v-for="item in miniMenuObj" :key="item.value" :label="item.label" :name="item.value">
                </el-tab-pane>
            </el-tabs>
            <!-- 查询 -->
            <section class="table-search">
                <div class="search-L">
                    <el-radio-group v-model="queryParams.queryType" class="mr15" @change="onTab">
                        <el-radio-button v-for="item in ObjToOpt(echartDateType)" :key="item.value"
                            :label="item.value">{{
                                item.label }}</el-radio-button>
                    </el-radio-group>
                    <WeekRange @change="weekChange" v-if="queryParams.queryType == 2" ref="weekComponent"/>
                    <Month @change="monthChange" v-if="queryParams.queryType == 3" />
                </div>
                <div class="search-R"></div>
            </section>
            <template v-if="currentMenu == 1">
                <p class="mb-40 headData">
                    <b>总营业额（元）：{{ chartInfo.totalRevenue }}</b>
                    <b>商品零售营收（元）：{{ chartInfo.merRevenue }}</b>
                    <b>增值服务营收（元）：{{ chartInfo.serviceRevenue }}</b>
                    <b>包厢营收总额（元）：{{ chartInfo.roomRevenue }}</b>
                    <b>包厢日均坪效（元/平方/天）：{{ chartInfo.roomDAAvg }}</b>
                    <b>包厢日均（元/天）：{{ chartInfo.roomAvg }}</b>
                </p>
                <!-- 图表 -->
                <div class="echart">
                    <AllAmount ref="allAmount" style="width: 48%;" :echartData="chartInfo.listData" />
                    <DayAmount ref="dayAmount" style="width: 48%;" :echartData="chartInfo.listData" />
                </div>
            </template>
            <template v-if="currentMenu == 2">
                <p class="mb-40 headData">
                    <b>订单数量（个）：{{ chartInfo.orderCount }}</b>
                    <b>订单均额（元/个）：{{ chartInfo.orderAvg }}</b>
                    <b>原单均额（元/个）：{{ chartInfo.orgAvg }}</b>
                    <b>续单均额（元/个）：{{ chartInfo.conAvg }}</b>
                    <b>续单数量（个）：{{ chartInfo.conCount }}</b>
                    <b>续单比率：{{ chartInfo.conRate?chartInfo.conRate+'%':'' }}</b>
                    <b>优惠折率：{{ chartInfo.disRate?chartInfo.disRate+'%':'' }}</b>
                </p>
                <!-- 图表 -->
                <div class="echart">
                    <OrderA ref="orderA" style="width: 48%;" :echartData="chartInfo.listData" />
                    <OrderB ref="orderB" style="width: 48%;" :echartData="chartInfo.listData" />
                    <OrderC ref="orderC" style="width: 48%;" :echartData="chartInfo.listData" />
                    <OrderD ref="orderD" style="width: 48%;" :echartData="chartInfo.listData" />
                </div>
            </template>
            <template v-if="currentMenu == 3">
                <div style="display: flex;">
                    <div style="flex: 1;">
                        <b>支付方式组成（总营收：{{ chartInfo.totalIncome }}元）</b>
                        <div class="mb-40 headData">
                            <p>微信收入（元）：{{ chartInfo.wechatIncome }}</p>
                            <p>线下收入（元）：{{ chartInfo.beIncome }}</p>
                            <p>团购收入（元）：{{ chartInfo.grIncome }}</p>
                            <p>本金收入（元）：{{ chartInfo.prIncome }}</p>
                        </div>
                        <div style="height: 5rem;">
                            <Payment ref="payment" style="width: 100%;" :echartData="chartInfo.listData"/>
                        </div>

                    </div>
                    <div style="flex: 1;">
                        <b>业态组成（总营收：{{ chartInfo.roomTRevenue }}元）</b>
                        <div class="mb-40 headData">
                            <p v-for="(item, index) in chartInfo.serTotalRList" :key="index">{{
                                item.serviceName+'：'+item.totalRevenue }}</p>
                        </div>
                       <div style="height: 5rem;">
                        <Service ref="service3" style="width: 100%;" :echartData="chartInfo.listData" :type="1"/>
                       </div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { ObjToOpt } from "@/utils/utils";
import { echartDateType } from "@/db/objs";
import WeekRange from "@/components/echarts/child/weekRange.vue";
import Month from "@/components/echarts/child/month.vue";
import AllAmount from "@/components/echarts/child/allAmount.vue";
import DayAmount from "@/components/echarts/child/dayAmount.vue";
import OrderA from "@/components/echarts/child/orderA.vue";
import OrderB from "@/components/echarts/child/orderB.vue";
import OrderC from "@/components/echarts/child/orderC.vue";
import OrderD from "@/components/echarts/child/orderD.vue";
import Payment from "@/components/echarts/child/payment.vue";
import Service from "@/components/echarts/child/service.vue";
import { mapState } from "vuex";
import { saleTrend } from "@/api/home/echart.js";
import DataRange from "@/components/echarts/child/dataRange.vue";

export default {
    components: {
        WeekRange, Month, AllAmount, DayAmount, OrderA, OrderB, OrderC, OrderD, Payment, Service, DataRange
    },
    props: ["chartName", "merchantShopInfo"],
    computed: {
        ...mapState(['options'])
    },
    data() {
        return {
            ObjToOpt,
            echartDateType,
            // 编辑弹框
            editShow: false, //弹框开关
            editTitle: "", //弹框标题
            miniMenuObj: [
                {
                    label: "营收数据分析",
                    value: '1',
                },
                {
                    label: "订单数据分析",
                    value: '2',
                },
                {
                    label: "营收收入组成分析",
                    value: '3',
                },
            ],
            queryParams: {
                merchantId: "",
                shopId: "",
                queryType: '',//2周 3月
                startDate: '',
                endDate: '',
                serviceIdList: []
            },
            currentMenu:'1',
            chartInfo: {},
            value: [],
            serviceIncome: [],
        };

    },
    created() {
        this.init()
    },
    methods: {
        init() {
            const params = {
                merchantId: this.merchantShopInfo.merchantId,
                shopId: this.merchantShopInfo.shopId
            }
            this.$store.dispatch('getServiceTree', params)
        },

        onLink(e) {
            this.initChart()
        },
        // 获取图表数据
        getChartInfo() {
            saleTrend(this.queryParams).then(res => {
                this.chartInfo = res.data
                this.initChart()
            })
        },
        handleChange(value) {
            this.$store.commit('setSelectedService', value)
            let arr = value.map(item => item[1])
            this.queryParams.serviceIdList = arr
            this.$emit('handleChange', arr)
        },
        initChart() {
            this.$nextTick(() => {
                if (this.currentMenu == 1) {
                    this.$refs.allAmount.myChartFn()
                    this.$refs.dayAmount.myChartFn()
                } else if (this.currentMenu == 2) {
                    this.$refs.orderA.myChartFn()
                    this.$refs.orderB.myChartFn()
                    this.$refs.orderC.myChartFn()
                    this.$refs.orderD.myChartFn()
                } else if (this.currentMenu == 3) {
                    this.$refs.payment.myChartFn()
                    this.$refs.service3.myChartFn()
                }

            })
        },
        //【监听】打开图表
        onChart() {
            this.queryParams.merchantId = this.merchantShopInfo.merchantId
            this.queryParams.shopId = this.merchantShopInfo.shopId
            this.currentMenu='1'
            this.queryParams.queryType = 2;
            this.editShow = true;
            this.$nextTick(() => {
            this.$refs.weekComponent.getMondayOfCurrentWeek()
           })
        },

        // 【监听】时段选择
        onTab() {
            // this.getChartInfo()
        },


        weekChange(date) {
            this.queryParams.startDate = date.weekStart
            this.queryParams.endDate = date.weekEnd
            this.getChartInfo()
        },
        monthChange(date) {
            this.queryParams.startDate = date[0]
            this.queryParams.endDate = date[1]
            this.getChartInfo()

        },

        getSum(arr){
            let sum = 0
            arr.forEach(item => {
                sum += item
            })
            return sum.toFixed(2)
        
        }
    },
};
</script>

<style lang="scss" scoped>
.page {
    padding: 0.15rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-cascader {
        width: 5rem;
    }

    .top {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .subtitle {
            font-weight: 400;
            font-size: 0.16rem;
            color: #333333;
            width: 1rem;
        }

        .btn {
            font-weight: 500;
            font-size: 0.14rem;
            color: #EF9807;
        }
    }
}

.note {
    display: flex;
    align-items: center;
    padding-left: 0.2rem;

    p {
        width: 1rem;
    }

    background: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #979797;
}

.icons {
    font-size: 0.2rem;
    color: #ef9807;
    margin-bottom: 0.15rem;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.table-search {
    margin-bottom: 0.20rem;
    display: flex;
    justify-content: space-between;
}

.search-L {
    display: flex;
}

.echart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}




.mb-40 {
    margin-bottom: 0.4rem;
}

.mb-20 {
    margin-bottom: 0.2rem;
}

.headData {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.2rem;
    
    b {
        margin-bottom: 0.2rem;
        width: 25%;
    }

    p {
        margin-bottom: 0.2rem;
        width: 33%;
    }

}

.mr-20 {
    margin-right: 0.24rem;
}
</style>