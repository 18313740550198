<template>
    <div class="saleInfo">
        <div style="display: flex;align-items: center;margin-bottom: 0.15rem;">
            <div class="title mr20">营收汇总统计 {{'（'+this.startDate+' 至 '+deadline+'）'}}</div>
            <Week @change="changeDate" />
        </div>
       
        <div class="content">
            <Block :title="'当月营业总额/目标（元）'" :val="monthTotal" class="mr20" />
        </div>
        <div class="currentData">
            <SaleDetail :merchantShopInfo="merchantShopInfo" :chartName="'营收汇总统计趋势分析'" @handleChange="handleChange" />
            <div class="dashboard">
                <!-- 顶部行 -->
                <div class="top-row">
                    <div class="echartBox left">
                        <div class="card">
                            <div class="card_item">
                                <h3 v-if="revenueData.totalRevenue && revenueData.totalRevenuePercentage">{{
                                    revenueData.totalRevenue + '/' + revenueData.totalRevenuePercentage + '%' }}</h3>
                                <div>总营业额（元）/占比</div>
                                <span v-if="revenueData.totalRevenueRefund">退 -{{ revenueData.totalRevenueRefund
                                    }}</span>
                            </div>
                            <div class="card_item">
                                <h3>{{ revenueData.roomRevenueDailyAreaAvg }}</h3>
                                <div>包厢日均坪效（元/平方/天）</div>
                            </div>
                            <div class="card_item">
                                <h3>{{ revenueData.roomRevenueDailyAvg }}</h3>
                                <div>包厢日均（元/天）</div>
                            </div>
                        </div>
                        <div class="chart">
                            <h4>营业金额概况</h4>
                            <div ref="revenueChart" style="width: 100%;height:3.8rem;"></div>
                        </div>
                    </div>
                    <div class="echartBox right">
                        <div class="card_item">
                            <div style="display: flex;align-items: center;cursor: pointer;" @click="getOrderInfo">
                                <h3>{{ orderData.orderCount }}</h3><i class="el-icon-arrow-right"
                                    style="color: #EF9807;"></i>
                            </div>
                            <div>订单数量（个）</div>
                        </div>
                        <div class="card_item">
                            <h3>{{ orderData.orderAvgPrice }}</h3>
                            <div>订单均额（元/个）</div>
                        </div>
                        <div class="card_item">
                            <h3>{{ orderData.orgOrderAvgPrice }}</h3>
                            <div>原单均额（元/个）</div>
                        </div>
                        <div class="card_item">
                            <h3>{{ orderData.renewalAvgPrice }}</h3>
                            <div>续单均额（元/个）</div>
                        </div>
                        <div class="card_item">
                            <h3 v-if="orderData.renewalCount && orderData.renewalRate">{{ orderData.renewalCount + '/' +
                                orderData.renewalRate+'%' }}</h3>
                            <div>续单数量（个）/续单比率</div>
                        </div>
                        <div class="card_item">
                            <h3>{{ orderData.discountRate?orderData.discountRate+'%':'' }}</h3>
                            <div>优惠折扣率</div>
                        </div>
                    </div>
                </div>
                <!-- 底部行 -->
                <div class="bottom-row">
                    <div class="chart">
                        <h4>包厢营收-支付方式组成</h4>
                        <div ref="paymentChart" style="width: 100%;height: 100%;"></div>
                    </div>
                    <div class="chart">
                        <h4>包厢营收-包厢集合组成</h4>
                        <div ref="businessChart" style="width: 100%;height: 100%;"></div>
                    </div>
                </div>
            </div>
            <ServiceDetail :merchantShopInfo="merchantShopInfo" :chartName="'包厢集合统计趋势分析'" />
            <Table :column="column" @rowClick="onRowClick" :tableData="roomData" :headerCellStyle="headerCellStyle"
                :firstColumn="true">
                <template slot="weekRatioSlot" slot-scope="scope">
                    <span>{{ scope.row.weekRatio | formatStr }}</span>
                </template>
                <template slot="monthRatioSlot" slot-scope="scope">
                    <span>{{ scope.row.monthRatio | formatStr }}</span>
                </template>
                <template slot="orderAmountSlot" slot-scope="scope">
                    <div>{{ scope.row.orderAmount }}</div>
                    <div style="color: red;font-size: 12px;line-height: 14px;" v-if="scope.row.orderAmountRefund">{{ '退'
                        +
                        scope.row.orderAmountRefund }}</div>
                </template>
                <template slot="discountRateSlot" slot-scope="scope">
                    <span>{{ scope.row.discountRate ? scope.row.discountRate + '%' : '' }}</span>
                </template>
            </Table>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog title="包厢营收明细" :visible.sync="showDialog" :close-on-click-modal="false" append-to-body width="80%"
            center @open="openDialog" z-index="1000">
            <dataRange v-if="dataRangeComType == 1" style="margin-bottom: 10px;" />
            <el-input v-if="dataRangeComType == 2" disabled v-model="currentRoomName" style="margin-bottom: 10px;">
                <template slot="prepend">统计范围：</template>
            </el-input>

            <!-- 查询 -->
            <section class="table-search">
                <div class="search-L" style="display: flex;">
                    <el-radio-group v-model="tableParams.queryType" class="mr15" @change="onTab">
                        <el-radio-button v-for="item in ObjToOpt(echartDateType)" :key="item.value"
                            :label="item.value">{{
                                item.label }}</el-radio-button>
                    </el-radio-group>
                    <WeekRange @change="weekChange" v-if="tableParams.queryType == 2" ref="weekComponent" />
                    <Month @change="monthChange" v-if="tableParams.queryType == 3" />
                </div>
            </section>
            <Table :column="column2" :tableData="tableData" style="margin: 20px 0;">
                <template slot="roomDiscountRate" slot-scope="scope">
                    <span>{{ scope.row.roomDiscountRate ? scope.row.roomDiscountRate + '%' : '' }}</span>
                </template>
            </Table>
            <!-- 分页 -->
            <section class="table-footer">
                <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                    :pageSize-size="tableParams.pageSize" @size-change="onSizeChange"
                    :pageSize-sizes="[10, 20, 50, 100]" :current-page.sync="tableParams.page"
                    @current-change="onCurrentPage">
                </el-pagination>
            </section>

        </el-dialog>
    </div>
</template>
<script>
import { ObjToOpt } from "@/utils/utils";
import { echartDateType } from "@/db/objs";
import WeekRange from "@/components/echarts/child/weekRange.vue";
import Week from "@/components/echarts/child/week.vue";
import Month from "@/components/echarts/child/month.vue";
import Block from "@/components/echarts/child/block.vue";
import Table from "@/components/echarts/child/table.vue";
import SaleDetail from "@/components/echarts/child/saleDetail.vue";
import ServiceDetail from "@/components/echarts/child/serviceDetail.vue";
import DataRange from "@/components/echarts/child/dataRange.vue";
import { saleInfo, weekSaleInfo, weekOrderInfo, weekRoomInfo, revenueDetail } from "@/api/home/echart.js";
import * as echarts from "echarts";
export default {
    name: 'saleInfo',
    components: {
        Block, Table, SaleDetail, ServiceDetail, DataRange, Month,WeekRange,Week
    },
    props: ['merchantShopInfo'],
    data() {
        return {
            ObjToOpt,
            echartDateType,
            deadline: '',
            monthTotal: '',
            dataRangeComType: 1,
            headerCellStyle: {
                background: 'rgba(239, 152, 7, 0.02)',
                color: '#000',
                borderColor: 'rgba(239, 152, 7, 0.15)',
                fontWeight: 400,
                fontSize: '0.14rem',
                textAlign: 'center',
            },
            column: [
                {
                    type: '',
                    label: '',
                    prop: 'labelName',
                    width: '180px'
                },

                {
                    type: '',
                    label: '周一',
                    prop: 'mondayData',
                },
                {
                    type: '',
                    label: '周二',
                    prop: 'tuesdayData',
                },
                {
                    type: '',
                    label: '周三',
                    prop: 'wednesdayData',
                },
                {
                    type: '',
                    label: '周四',
                    prop: 'thursdayData',
                },
                {
                    type: '',
                    label: '周五',
                    prop: 'fridayData',
                },
                {
                    type: '',
                    label: '周六',
                    prop: 'saturdayData',
                },
                {
                    type: '',
                    label: '周日',
                    prop: 'sundayData',
                },
                {
                    type: '',
                    label: '周累计',
                    prop: 'weekTotal',
                },
                {
                    type: '',
                    label: '周同比',
                    prop: 'weekRatio',
                    dataType: 'slot',
                    slot: 'weekRatioSlot',
                },
                {
                    type: '',
                    label: '月累计',
                    prop: 'monthTotal',

                },
                {
                    type: '',
                    label: '月同比',
                    prop: 'monthRatio',
                    dataType: 'slot',
                    slot: 'monthRatioSlot',

                },
                {
                    type: '',
                    label: '应收',
                    prop: 'orgOrderAmount',
                },
                {
                    type: '',
                    label: '实收',
                    prop: 'orderAmount',
                    width: '120px',
                    dataType: 'slot',
                    slot: 'orderAmountSlot',
                },

                {
                    type: '',
                    label: '折扣率',
                    prop: 'discountRate',
                    dataType: 'slot',
                    slot: 'discountRateSlot',
                },

            ],
            column2: [
                {
                    type: '',
                    label: '时间',
                    prop: 'statisDayStr',

                },
                {
                    type: '',
                    label: '包厢名称',
                    prop: 'roomName',
                },
                {
                    type: '',
                    label: '订单数量',
                    prop: 'roomOrderCount',
                },
                {
                    type: '',
                    label: '订单时长（小时）',
                    prop: 'roomOrderTime',
                },
                {
                    type: '',
                    label: '日均营收',
                    prop: 'roomRevenueAvgDay',
                },
                {
                    type: '',
                    label: '累计营收',
                    prop: 'roomRevenue',
                },
                {
                    type: '',
                    label: '折扣率',
                    prop: 'roomDiscountRate',
                    dataType: 'slot',
                    slot: 'roomDiscountRate',
                },

            ],
            // 编辑弹

            showDialog: false,
            tableParams: {
                page: 1,
                pageSize: 10,
                queryType: 2,//2周 3月
                serviceIdList: [],
                merchantId: '',
                shopId: '',
                startDate: '',
                endDate: ''
            },
            queryParams: {
                serviceIdList: [],
                merchantId: '',
                shopId: ''
            },
            tableData: [],
            total: 0,
            revenueData: {},
            orderData: {},
            roomData: [],
            currentRoomName: "",
            startDate: '',
        }
    },
    created() {
        // this.init();
    },

    filters: {
        formatStr(val) {
            if (val) {
                if (val > 0) {
                    return '+' + val + '%'
                } else {
                    return val + '%'
                }
            } else {
                return ''
            }
        },
    },
    mounted() {

    },
    methods: {
        init() {
            this.queryParams.merchantId = this.merchantShopInfo.merchantId;
            this.queryParams.shopId = this.merchantShopInfo.shopId;
            this.queryParams.startDate=this.startDate
            this.tableParams.merchantId = this.merchantShopInfo.merchantId;
            this.tableParams.shopId = this.merchantShopInfo.shopId;
            this.tableParams.startDate=this.startDate
            this.saleInfo();
            this.getWeekSaleInfo();
            this.getWeekOrderInfo();
            this.getWeekRoomInfo();
        },
        changeDate(date) {
            this.startDate = date
            this.init()
        },
        // 当月营业总额/目标
        saleInfo() {
            saleInfo(this.queryParams).then(res => {
                this.deadline = res.data.deadline;
                this.monthTotal = res.data.currentRevenue + '/' + res.data.currentRevenueTarget;
            })
        },
        //   获取营业金额概况图表数据
        getWeekSaleInfo() {
            weekSaleInfo(this.queryParams).then(res => {
                this.revenueData = res.data
                this.renderCharts();
            })
        },
        // 营收当周数据-订单
        getWeekOrderInfo() {
            weekOrderInfo(this.queryParams).then(res => {
                this.orderData = res.data
            })
        },
        // 营收当周数据-订单
        getWeekRoomInfo() {
            weekRoomInfo(this.queryParams).then(res => {
                this.roomData = res.data
            })
        },
        // 选择业态集合
        handleChange(e) {
            this.queryParams.serviceIdList = e;
            this.init()
        },
        // 按周按月
        onTab() {
            // this.reload()
        },
        renderCharts() {
            // 营业金额概况图表
            const revenueChart = echarts.init(this.$refs.revenueChart);
            const roomList = []
            this.revenueData.stotalRList.forEach(item => {
                roomList.push({
                    name: item.serviceName,
                    value: item.totalRevenue
                })
            })
            const revenueData = [
                { value: this.revenueData.merchandiseRevenue, name: '商品零售营收', reBack: this.revenueData.merchandiseRevenueRefund },
                { value: this.revenueData.increment, name: '增值服务营收', reBack: this.revenueData.incrementRevenueRefund },
                { value: this.revenueData.roomRevenue, name: '包厢营收总额', reBack: this.revenueData.roomRevenueRefund }
            ]
            revenueChart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `
              ${params.name}： ${params.value} (${params.percent}%)<br/>
               <span style="color: red;">退款：- ${params.data.reBack}</span>
            `;
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['30%', '58%'],
                        center: ['40%', '50%'], // 调整饼图位置
                        data: revenueData,
                        labelLine: {
                            normal: {
                                length1: 20,
                                length2: 30
                            }
                        },

                        label: {
                            formatter: function (params) {
                                return `{a|${params.name}}\n{b|${params.value}} ({per|${params.percent}%})\n{reBack|退 -${params.data.reBack}}`;
                            },
                            rich: {
                                a: {
                                    color: 'rgba(0,0,0,0.65)',
                                    fontSize: 12,
                                    fontWeight: '400',
                                    lineHeight: 17
                                },
                                b: {
                                    color: 'rgba(0,0,0,0.65)',
                                    fontSize: 12,
                                    fontWeight: '400',
                                    lineHeight: 14
                                },
                                per: {
                                    color: 'rgba(0,0,0,0.65)',
                                    fontSize: 12,
                                    fontWeight: '400',
                                    lineHeight: 14
                                },
                                reBack: {
                                    color: '#FF1919',
                                    lineHeight: 14,
                                    fontSize: 10,
                                    fontWeight: '400',
                                    lineHeight: 14

                                }
                            }


                        }
                    }
                ],
                legend: {
                    left: 'center',
                    bottom: '0',

                },
            });

            // 支付方式组成图表
            const paymentChart = echarts.init(this.$refs.paymentChart);
            paymentChart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `
              ${params.name}: ${params.value} (${params.percent}%)`;
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        center: ['40%', '50%'], // 调整饼图位置
                        data: [
                            { value: this.revenueData.wechatIncome, name: '微信收入' },
                            { value: this.revenueData.belowIncome, name: '线下收入' },
                            { value: this.revenueData.groupPurchaseIncome, name: '团购收入' },
                            { value: this.revenueData.principalIncome, name: '本金收入' }
                        ],
                        label: {
                            formatter: function (params) {
                                return `{a|${params.name}}\n${params.value} ({per|${params.percent}%})`;
                            }
                            ,
                            rich: {
                                a: {
                                    lineHeight: 22,
                                },
                            }
                        }
                    }
                ],
                legend: {
                    orient: 'vertical',
                    right: 50,
                    bottom: '10%'
                },
            });

            // 业态组成图表
            const businessChart = echarts.init(this.$refs.businessChart);
            businessChart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `
              ${params.name}: ${params.value} (${params.percent}%)`;
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        center: ['40%', '50%'], // 调整饼图位置
                        data: roomList,
                        label: {
                            formatter: function (params) {
                                return `{a|${params.name}}\n${params.value} ({per|${params.percent}%})`;
                            }
                            ,
                            rich: {
                                a: {
                                    lineHeight: 22,
                                },
                            }
                        }
                    }
                ],
                legend: {
                    orient: 'vertical',
                    right: 50,
                    bottom: '10%'
                },
            });
        },
        // 点击订单数量
        getOrderInfo() {
            this.dataRangeComType = 1;
            this.tableParams.serviceIdList = this.queryParams.serviceIdList;
            this.showDialog = true;
        },

        // 点击包厢集合表格行
        onRowClick(row, column, event) {
            this.dataRangeComType = 2
            this.tableParams.serviceIdList = [row.serviceIds];
            this.currentRoomName = row.labelName;
            this.showDialog = true;
        },

        openDialog() {
            this.tableParams.queryType = 2;
            this.$nextTick(() => {
                this.$refs.weekComponent.getMondayOfCurrentWeek()
            })

        },
        weekChange(date) {
            this.tableParams.startDate = date.weekStart
            this.tableParams.endDate = date.weekEnd
            console.log('weekChange')
            this.reload()
        },
        monthChange(date) {
            this.tableParams.startDate = date[0]
            this.tableParams.endDate = date[1]
            this.reload()
            console.log('monthChange')
        },
        // 【请求】卡券操作记录
        getTableList() {
            revenueDetail(this.tableParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.getTableList();
        },

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableList();
        },
        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.pageSize = size;
            this.reload();
        },
    }
}
</script>
<style scoped lang="scss">
.saleInfo {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0.21rem 0.26rem;
    margin-bottom: .15rem;
    box-sizing: border-box;
    .content {
        display: flex;
        flex-wrap: wrap;
    }

    .mr20 {
        margin-right: 0.2rem;
    }

    .currentData {
        margin-top: 0.22rem;
        margin-bottom: 0.15rem;

        .top {
            display: flex;
            justify-content: space-between;

            .subtitle {
                font-weight: 400;
                font-size: 0.16rem;
                color: #333333;
                margin-bottom: 0.15rem;
            }

            .btn {
                font-weight: 500;
                font-size: 0.14rem;
                color: #EF9807;
            }
        }

        .dashboard {
            display: flex;
            flex-direction: column;
            margin-bottom: 0.37rem;
        }

        .top-row {
            display: flex;
            justify-content: space-between;

            .echartBox {
                display: flex;
                width: 7.81rem;
                border-radius: 0.08rem;
                border: 1px solid #EEEEEE;
            }

            .left {
                justify-content: space-between;
                // padding-left: 0.5rem;
                margin-right: 0.2rem;

                .card {
                    width: 2.76rem;

                    .card_item {
                        padding: 0.34rem 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h3 {
                            font-weight: 600;
                            font-size: 0.35rem;
                            color: #343643;
                            line-height: 0.49rem;
                            margin-bottom: 0.07rem;
                        }

                        div {
                            font-weight: 400;
                            font-size: 0.14rem;
                            color: #343643;
                            line-height: 0.2rem;
                            text-align: center;
                        }

                        span {
                            font-weight: 400;
                            font-size: 0.12rem;
                            color: #FF1919;
                        }
                    }

                    .card_item:nth-child(1) {
                        padding-bottom: 0.23rem;

                    }

                    .card_item:nth-child(2) {
                        border-top: 1px solid #EEEEEE;
                        border-bottom: 1px solid #EEEEEE;
                    }
                }

                .chart {
                    width: 5.14rem;
                    height: 3.5rem;
                    padding-top: 0.36rem;


                    h4 {
                        text-align: center;
                        font-weight: 400;
                        font-size: 0.16rem;
                        color: #000000;
                        line-height: 0.22rem;
                    }

                }
            }

            .right {
                padding: 0.4rem 1.26rem;
                flex-wrap: wrap;
                justify-content: space-around;

                .card_item {
                    width: 1.76rem;
                    margin-right: 0.87rem;
                    padding: 0.34rem 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h3 {
                        font-weight: 600;

                        font-size: 0.35rem;
                        color: #343643;
                        line-height: 0.49rem;
                    }

                    div {
                        margin-top: 0.12rem;
                        font-weight: 400;
                        font-size: 0.14rem;
                        color: #343643;
                        line-height: 0.2rem;
                        text-align: center;
                    }
                }

                .card_item:nth-child(2n) {
                    margin-right: 0;
                }

                .card_item:nth-child(3),
                .card_item:nth-child(4) {
                    border-top: 1px solid #EEEEEE;
                    border-bottom: 1px solid #EEEEEE;
                }
            }
        }

        .bottom-row {
            display: flex;
            width: 100%;
            height: 4.7rem;
            border-radius: 0.08rem;
            border: 0.01rem solid #EEEEEE;
            padding-top: 0.34rem;
            padding-left: 0.4rem;
            box-sizing: border-box;
            margin-top: 0.2rem;

            .chart {
                width: 50%;
                height: 100%;

                h4 {
                    font-weight: 400;
                    font-size: 0.16rem;
                    color: #000000;
                }
            }
        }
    }

    .table-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.20rem;
    }

    .search-L {
        display: flex;
    }

}
</style>