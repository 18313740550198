<template>
    <div class="lineChart">
        <div id="vipChart" ref="vipChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["chartName", "merchantShopInfo", "echartData"],
    data() {
        return {
            // 编辑弹框
            editShow: false, //弹框开关
            editTitle: "", //弹框标题
            vipChart: "",
        };
    },
    computed: {
        // 日期
        dateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.snapDay];
                })
            }
            return arr;
        },
        // 	新增会员数
        newMembersList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.newMembers];
                })
            }
            return arr;
        },
        // 新增订单用户数	
        newOUserList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.newOUser];
                })
            }
            return arr;
        },


    },
    methods: {
        // 【渲染】折线图
        myChartFn() {
            if (this.vipChart == "") {
                this.vipChart = echarts.init(this.$refs.vipChart);
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },

                legend: {
                    data: ['新增会员', '新增订单用户']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.dateList,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '数量（个）',
                        min: 0,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                series: [
                    {
                        name: '新增会员',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '个';
                            }
                        },
                        data: this.newMembersList
                    },
                    {
                        name: '新增订单用户',
                        type: 'line',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '个';
                            }
                        },
                        data: this.newOUserList
                    }
                ]
            };

            this.vipChart.setOption(option);
        }
    },
};
</script>

<style lang="scss" scoped>
.lineChart {
    width: 6.74rem;
    height: 3.3rem;
}
</style>