<template>
    <div id="monthPicker">
        <el-date-picker v-model="month" type="monthrange" format="yyyy年MM月" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始月份"
            end-placeholder="结束月份" @change="change" unlink-panels :clearable="false">
        </el-date-picker>
    </div>
</template>

<script>

import { ObjToOpt, formatterDate } from "@/utils/utils";
export default {
    name: 'Month',
    data() {
        return {
            formatterDate,
            month:[],
        }
    },
    created() {
        this.setDefaultMonthRange();
    },
    methods: {
        change(month) {
            const arr=month?month:[]
            this.$emit('change',arr)
        },
        setDefaultMonthRange() {
            const now = new Date();
            const start = new Date(now.getFullYear(), now.getMonth(), 1);
            const end = new Date(now.getFullYear(), now.getMonth(), 1);
            this.month = [this.formatterDate(start), this.formatterDate(end)];
            this.$emit('change',this.month)
        },
    }
}
</script>

<style scoped>
/* Your CSS content goes here */
h1 {
    color: #42b983;
}
</style>