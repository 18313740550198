<template>
  <div class="home">
    <BaseInfo :merchantShopInfo="merchantShopInfo" ref="baseInfo"/>
    <UserInfo :merchantShopInfo="merchantShopInfo"/>
    <SaleInfo :merchantShopInfo="merchantShopInfo"/>
    <SortInfo :merchantShopInfo="merchantShopInfo"/>
  </div>
</template>

<script>
import BaseInfo  from "@/components/echarts/baseInfo.vue";
import UserInfo  from "@/components/echarts/userInfo.vue";
import SaleInfo from "@/components/echarts/saleInfo.vue";
import SortInfo from "@/components/echarts/sortInfo.vue";
import FinanceModule from "@/views/home/FinanceModule.vue";
import UserModule from "@/views/home/UserModule.vue";
import CardSell from "@/views/home/CardSell.vue";
import RoomSell from "@/views/home/RoomSell.vue";
export default {
  components: { BaseInfo,UserInfo,SaleInfo,SortInfo,FinanceModule, UserModule, CardSell, RoomSell },
  data() {
    return {
      merchantShopInfo: {
        merchantId: "",
        shopId: "",
      },
    }
  },
  created() {
    let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
    if (merchantShopInfo) {
      merchantShopInfo.merchantIdSet = [merchantShopInfo.merchantId]
      merchantShopInfo.shopIdSet = [merchantShopInfo.shopId]
      this.merchantShopInfo = merchantShopInfo;
    }
  },
  mounted() {
    // this.$refs.baseInfo.init();
    // this.$refs.userInfo.initData();
    // this.$refs.saleInfo.initData();
    // this.$refs.sortInfo.initData();
  },
}
</script>

<style lang="scss" scoped>
.home {
  background: #f0f2f5;
}

.sell {
  display: flex;
  justify-content: space-between;
}
</style>