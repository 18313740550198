<template>
    <div class="page">
        <div class="top">
            <div class="subtitle">当周数据</div>
            <div class="btn" @click="onChart">查看趋势分析<i class="el-icon-arrow-right"></i> </div>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :title="chartName" :visible.sync="editShow" :close-on-click-modal="false" 
            width="80%" center @open="openDialog" :modal-append-to-body=false append-to-body z-index="1000">
            <!-- 查询 -->
            <section class="table-search">
                <div class="search-L">
                    <el-radio-group v-model="queryParams.queryType" class="mr15" @change="onTab">
                        <el-radio-button v-for="item in ObjToOpt(echartDateType)" :key="item.value" :label="item.value">{{
                            item.label }}</el-radio-button>
                    </el-radio-group>
                    <WeekRange @change="weekChange" v-if="queryParams.queryType==2" ref="weekComponent"/>
                    <Month @change="monthChange" v-if="queryParams.queryType==3"/>
                </div>
                <div class="search-R"></div>
            </section>
            <p class="mb-40 headData">
                <b class="mr-20">新增会员（个）：{{ chartInfo.newMembers }}</b>
                <b class="mr-20">会员充值（元）：{{ chartInfo.memRecharge }}</b>
                <b class="mr-20">会员充值均值（元）：{{ chartInfo.memRAvg }}</b>
                <b class="mr-20">会员充值余额（元）：{{ chartInfo.memBalance }}</b>
                <b class="mr-20">新增订单用户（个）：{{ chartInfo.newOUser }}</b>
                <b class="mr-20">新增订单用户会员转化率：{{ chartInfo.newOUCRate?chartInfo.newOUCRate+'%':'' }}</b>
                <b class="mr-20">门店会员平台活跃率：{{ chartInfo.smalRate?chartInfo.smalRate+'%':'' }}</b>
                <b class="mr-20">门店会员本店活跃率：{{ chartInfo.smhalRate?chartInfo.smhalRate+'%':''}}</b>
            </p>
            <!-- 图表 -->
            <div class="echart">
                <VipChart ref="vipChart" style="width: 48%;" :echartData="chartInfo.list"/>
                <RechargeChart ref="rechargeChart" style="width: 48%;" :echartData="chartInfo.list"/>
            </div>
            <Efficiency ref="efficiency" style="width: 100%;" :echartData="chartInfo.list"/>
        </el-dialog>
    </div>
</template>

<script>
import { ObjToOpt, formatterDate } from "@/utils/utils";
import { echartDateType } from "@/db/objs";
import VipChart from "@/components/echarts/child/vipChart";
import RechargeChart from "@/components/echarts/child/rechargeChart.vue";
import Efficiency from "@/components/echarts/child/efficiency.vue";
import WeekRange from "@/components/echarts/child/weekRange.vue";
import Month from "@/components/echarts/child/month.vue";
import { memberTrend } from "@/api/home/echart";
export default {
    components: {
        VipChart, RechargeChart, Efficiency, WeekRange,Month
    },
    props: ["chartName", "merchantShopInfo"],
    data() {
        return {
            formatterDate,
            ObjToOpt,
            echartDateType,
            // 编辑弹框
            editShow: false, //弹框开关
            editTitle: "", //弹框标题
            dateToDate: [],
            queryParams: {
                merchantId: "",
                shopId: "",
                queryType:2,//2周 3月
                startDate:'',
                endDate:'',
            },
            chartInfo:{},
        };
    },

    methods: {
        initChart() {
            this.$nextTick(() => {
                this.$refs.vipChart.myChartFn()
                this.$refs.rechargeChart.myChartFn()
                this.$refs.efficiency.myChartFn()
            })
        },
        // 获取图表数据
        getChartInfo() {
            memberTrend(this.queryParams).then(res => {
                this.chartInfo = res.data
                this.initChart()
            })
        },
        //【监听】打开图表
        onChart() {
            this.queryParams.merchantId = this.merchantShopInfo.merchantId
            this.queryParams.shopId = this.merchantShopInfo.shopId
            this.queryParams.queryType = 2
            this.editShow = true;
        },
        openDialog() {
           this.$nextTick(() => {
            this.$refs.weekComponent.getMondayOfCurrentWeek()
           })
         
        },
        // 【监听】时段选择
        onTab() {
            // this.getChartInfo()
        },
        weekChange(date) {
            this.queryParams.startDate = date.weekStart
            this.queryParams.endDate = date.weekEnd
            this.getChartInfo()
 
        },
        monthChange(date) {
            this.queryParams.startDate = date[0]
            this.queryParams.endDate = date[1]
            this.getChartInfo()
        },
      
    }

}
</script>

<style lang="scss" scoped>
.page {

    padding: 0.15rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .top {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .subtitle {
            font-weight: 400;
            font-size: 0.16rem;
            color: #333333;
            margin-bottom: 0.15rem;
        }

        .btn {
            font-weight: 500;
            font-size: 0.14rem;
            color: #EF9807;
        }
    }
}

.icons {
    font-size: 0.2rem;
    color: #ef9807;
    margin-bottom: 0.15rem;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.table-search {
    margin-bottom: 0.20rem;
    display: flex;
    justify-content: space-between;
}

.search-L {
    display: flex;
}

.echart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}




.mb-40 {
    margin-bottom: 0.4rem;
}

.headData {
    display: flex;
    flex-wrap: wrap;

    b {
        width: 22%;
        margin-bottom: 0.2rem;
    }
}

.mr-20 {
    margin-right: 24px;
}
</style>