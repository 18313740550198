import { render, staticRenderFns } from "./shopData.vue?vue&type=template&id=7f691d15&scoped=true&"
import script from "./shopData.vue?vue&type=script&lang=js&"
export * from "./shopData.vue?vue&type=script&lang=js&"
import style0 from "./shopData.vue?vue&type=style&index=0&id=7f691d15&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f691d15",
  null
  
)

export default component.exports