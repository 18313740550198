<template>
    <div class="block">
        <div class="val">{{ val }}</div>
        <div class="title">{{ title }}</div>
    </div>
</template>

<script>
export default {
    name: "Block",
    props: {
        val: {
            type: [String, Number],
            default: '',
        },
        title: {
            type: String,
            default: '',
        }

    },
    data() {
        return {

        };
    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.block {
    width: 3.8rem;
    height:1.23rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.2rem;
    .val {
        font-weight: 600;
        font-size: 0.35rem;
        color: #333333;
        line-height: 0.49rem;
        margin-bottom: .10rem;
    }

    .title {
        font-weight: 400;
        font-size: 0.14rem;
        color: #333333;
        line-height: 0.2rem;
    }
}
</style>