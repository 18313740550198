/* vuex配置文件 */
import Vue from "vue";
import Vuex from "vuex";
import { serviceTree} from "@/api/home/echart.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    LOADING: false,
    selectedService: null,
    options: [],
  },
  mutations: {
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
    setSelectedService(state, service) {
      state.selectedService = service;
    },
    setOptions(state, options) {
      state.options = options;
    }
  },
  actions: {
   getServiceTree(context,val) {
      serviceTree(val).then((res) => {
        context.commit("setOptions", res.data);
      });
   }
  },
});
export default store;
