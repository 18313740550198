<template>
    <div class="page">
        <div class="top">
            <div class="subtitle">包厢集合统计-当周数据</div>
            <div class="btn" @click="onChart">查看趋势分析<i class="el-icon-arrow-right"></i> </div>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :title="chartName" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="80%"
            center z-index="1000">
            <DataRange />
            <!-- 查询 -->
            <section class="table-search">
                <div class="search-L">
                    <el-radio-group v-model="queryParams.queryType" class="mr15" @change="onTab">
                        <el-radio-button v-for="item in ObjToOpt(echartDateType)" :key="item.value"
                            :label="item.value">{{
                                item.label }}</el-radio-button>
                    </el-radio-group>
                    <WeekRange @change="weekChange" v-if="queryParams.queryType == 2" ref="weekComponent"/>
                    <Month @change="monthChange" v-if="queryParams.queryType == 3" />
                </div>
                <div class="search-R"></div>
            </section>
            <p class="mb-40 headData">
                <b class="mr-20" v-for="(item, index) in countList" :key="index">{{
                    item.serviceName + '：' + ((index + 1) % 2 == 0 ? item.dailyRAvg : item.roomRevenue) }}</b>
            </p>
            <!-- 图表 -->
            <div class="echart">
                <template v-if="chartInfo.listData&&chartInfo.listData.length>0">
                    <Service ref="service1" style="width: 48%;height: 100%;" :echartData="chartInfo.listData" :type="1" />
                    <Service ref="service2" style="width: 48%;height: 100%;" :echartData="chartInfo.listData" :type="2" />
                </template>
                <el-empty v-else description="暂无数据" style="margin: 0 auto;"></el-empty>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ObjToOpt } from "@/utils/utils";
import { echartDateType } from "@/db/objs";
import WeekRange from "@/components/echarts/child/weekRange.vue";
import Month from "@/components/echarts/child/month.vue";
import Service from "@/components/echarts/child/service.vue";
import DataRange from "@/components/echarts/child/dataRange.vue";
import { saleTrend } from "@/api/home/echart.js";
import { mapState } from "vuex";
export default {
    components: {
        DataRange, Service, WeekRange, Month,
    },
    props: ["chartName", "merchantShopInfo"],
    computed: {
			...mapState(['selectedService'])
		},
    data() {
        return {
            ObjToOpt,
            echartDateType,
            // 编辑弹框
            editShow: false, //弹框开关
            editTitle: "", //弹框标题
            queryParams: {
                merchantId: "",
                shopId: "",
                queryType: '',//2周 3月
                startDate: '',
                endDate: '',
                serviceIdList:[]
            },
            chartInfo: "",
            countList: []

        };
    },

    methods: {
        initChart() {
           this.$nextTick(() => {
            this.$refs.service1.myChartFn()
            this.$refs.service2.myChartFn()
           })
        },
        // 获取图表数据
        getChartInfo() {
            this.queryParams.serviceIdList = this.selectedService&&this.selectedService.length>0?this.selectedService.map(item => item[1]):[]
            saleTrend(this.queryParams).then(res => {
                this.chartInfo = res.data
                this.countList = []
                if (res.data.serTotalRList.length > 0) {
                    for (let i = 0; i < res.data.serTotalRList.length; i++) {
                        this.countList.push(res.data.serTotalRList[i])
                        this.countList.push(res.data.serAvgList[i])
                    }
                }
                if(res.data.listData.length>0){
                    this.initChart()
                }
            })
        },
        //【监听】打开图表
        onChart() {
            this.queryParams.merchantId = this.merchantShopInfo.merchantId
            this.queryParams.shopId = this.merchantShopInfo.shopId
            this.queryParams.queryType = 2;
            this.editShow = true;
            // 避免首次打开重复加载
            if (this.queryParams.startDate != '' && this.queryParams.endDate != '') {
                this.getChartInfo()
            }

        },

        // 【监听】时段选择
        onTab() {
            this.getChartInfo()
        },
        weekChange(date) {
            this.queryParams.startDate = date.weekStart
            this.queryParams.endDate = date.weekEnd
            this.getChartInfo()
        },
        monthChange(date) {
            this.queryParams.startDate = date[0]
            this.queryParams.endDate = date[1]
            this.getChartInfo()

        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    padding: 0.15rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .top {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .subtitle {
            font-weight: 400;
            font-size: 0.16rem;
            color: #333333;
            margin-bottom: 0.15rem;
        }

        .btn {
            font-weight: 500;
            font-size: 0.14rem;
            color: #EF9807;
        }
    }
}

.icons {
    font-size: 0.2rem;
    color: #ef9807;
    margin-bottom: 0.15rem;
}

.column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.table-search {
    margin-bottom: 0.20rem;
    display: flex;
    justify-content: space-between;
}

.search-L {
    display: flex;
}

.echart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
}


.mb-40 {
    margin-bottom: 0.4rem;
}

.mr-20 {
    margin-right: 24px;
}

.headData {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.2rem;

    b {
        margin-bottom: 0.2rem;
        width: 22%;
    }

}
</style>