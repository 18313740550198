<template>
    <div class="sell">
        <Popular title="最受欢迎的包厢（top10）" :merchantShopInfo="merchantShopInfo"/>
        <Revenue title="营收最高的包厢（top10）" :merchantShopInfo="merchantShopInfo"/>
       
    </div>
</template>
<script>
import Revenue from "@/components/echarts/revenue.vue";
import Popular from "@/components/echarts/popular.vue";
export default {
    name: 'sortInfo',
    components: { Revenue, Popular },
    props: ['merchantShopInfo'],
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    }

}
</script>
<style lang="scss" scoped>
.sell {
  display: flex;
  justify-content: space-between;
}
</style>