<template>
    <div class="userInfo">
        <div style="display: flex;align-items: center;margin-bottom: 0.15rem;">
            <div class="title mr20" v-if="currentDate">用户和会员 {{'（'+this.startDate+' 至 '+currentDate+'）'}}</div>
            <Week @change="changeDate" />
        </div>
        <div class="content">
            <Block v-for="(item, index) in labelList" :key="index" :title="item.label" :val="item.val"
                :class="(index + 1) % 4 == 0 ? '' : 'mr20'" />
        </div>
        <div class="currentData">
            <UserDetail :merchantShopInfo="merchantShopInfo" :chartName="'用户和会员趋势分析'" />
            <Table :column="columnData" :tableData="tableData" :headerCellStyle="headerCellStyle" :firstColumn="true">
                <template slot="weekRatioSlot" slot-scope="scope">
                    <span>{{ scope.row.weekRatio | formatStr }}</span>
                </template>
                <template slot="monthRatioSlot" slot-scope="scope">
                    <span>{{ scope.row.monthRatio | formatStr }}</span>
                </template>
            </Table>
        </div>
    </div>
</template>
<script>
import Block from "@/components/echarts/child/block.vue";
import Table from "@/components/echarts/child/table.vue";
import UserDetail from "@/components/echarts/child/userDetail.vue";
import Week from "@/components/echarts/child/week.vue";
import { memberInfo, weekMemberInfo } from "@/api/home/echart.js";
import { formatterDate } from "@/utils/utils";

export default {
    name: 'userInfo',
    components: {
        Block, Table, UserDetail, Week
    },
    props: ['merchantShopInfo'],
    data() {
        return {
            formatterDate,
            headerCellStyle: {
                background: 'rgba(239, 152, 7, 0.02)',
                color: '#000',
                borderColor: 'rgba(239, 152, 7, 0.15)',
                fontWeight: 400,
                fontSize: '0.14rem',
                textAlign: 'center',
            },
            columnData: [
                {
                    type: '',
                    label: '',
                    prop: 'labelName',
                    width: '180px',
                },

                {
                    type: '',
                    label: '周一',
                    prop: 'mondayData',
                },
                {
                    type: '',
                    label: '周二',
                    prop: 'tuesdayData',
                },
                {
                    type: '',
                    label: '周三',
                    prop: 'wednesdayData',
                },
                {
                    type: '',
                    label: '周四',
                    prop: 'thursdayData',
                },
                {
                    type: '',
                    label: '周五',
                    prop: 'fridayData',
                },
                {
                    type: '',
                    label: '周六',
                    prop: 'saturdayData',
                },
                {
                    type: '',
                    label: '周日',
                    prop: 'sundayData',
                },
                {
                    type: '',
                    label: '周累计',
                    prop: 'weekTotal',
                },
                {
                    type: '',
                    label: '周同比',
                    prop: 'weekRatio',
                    dataType: 'slot',
                    slot: 'weekRatioSlot',
                },
                {
                    type: '',
                    label: '月累计',
                    prop: 'monthTotal',
                },
                {
                    type: '',
                    label: '月同比',
                    prop: 'monthRatio',
                    dataType: 'slot',
                    slot: 'monthRatioSlot',
                },

            ],
            tableData: [
                {
                    labelName: '新增会员',
                    mondayData: 1,
                    tuesdayData: 1,
                    wednesdayData: 2,
                    thursdayData: 2,
                    fridayData: 3,
                    saturdayData: 4,
                    sundayData: 5,
                    weekRatio: 6,
                    weekTotal: 7,
                    monthRatio: 8,
                    monthTotal: 9
                },

            ],

            labelList: [
                {
                    key: 'cumulativeMembers',
                    label: '累计会员数（个）',
                    val: 0
                },
                {
                    key: 'newMembers',
                    label: '当月新增会员/目标（个）',
                    val: 0
                },
                {
                    key: 'c',
                    label: '当月新增订单用户/目标（个）',
                    val: 0
                },
                {
                    key: 'd',
                    label: '当周/当月新增订单用户会员转化率',
                    val: 0
                },
                {
                    key: 'e',
                    label: '当周/当月门店会员平台活跃率',
                    val: 0
                },
                {
                    key: 'f',
                    label: '当周/当月门店会员本店活跃率',
                    val: 0
                }],
            currentDate: '',
            startDate: '',
        }
    },
    filters: {
        formatStr(val) {
            if (val) {
                if (Number(val) > 0) {
                    return '+' + val + '%'
                } else {
                    return val + '%'
                }
            } else {
                return ''
            }
        },
    },
    created() {
        // this.init()
    },
    methods: {
        init() {
            const params = {
                merchantId: this.merchantShopInfo.merchantId,
                shopId: this.merchantShopInfo.shopId,
                startDate: this.startDate
            }
            memberInfo(params).then(res => {
                this.currentDate = res.data.deadline
                this.labelList[0].val = res.data.cumulativeMembers
                this.labelList[1].val = res.data.newMembers + '/' + res.data.newMembersTarget
                this.labelList[2].val = res.data.newOrderUser + '/' + res.data.newOrderMembersTarget
                this.labelList[3].val = res.data.weekMemberConversionRate + '%/' + res.data.monthMemberConversionRate + '%'
                this.labelList[4].val = res.data.weekPlatformActivityRate + '%/' + res.data.monthPlatformActivityRate + '%'
                this.labelList[5].val = res.data.weekShopActivityRate + '%/' + res.data.monthShopActivityRate + '%'
            })
            weekMemberInfo(params).then(res => {
                this.tableData = res.data
            })
        },
        changeDate(date) {
            this.startDate = date
            this.init()
        }
    }
}
</script>
<style scoped lang="scss">
.userInfo {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0.21rem 0.26rem;
    margin-bottom: .15rem;
    box-sizing: border-box;


    .content {
        display: flex;
        flex-wrap: wrap;
    }

    .mr20 {
        margin-right: 0.2rem;
    }

    .currentData {
        margin-top: 0.22rem;
        margin-bottom: 0.15rem;

        .top {
            display: flex;
            justify-content: space-between;

            .subtitle {
                font-weight: 400;
                font-size: 0.16rem;
                color: #333333;
                margin-bottom: 0.15rem;
            }

            .btn {
                font-weight: 500;
                font-size: 0.14rem;
                color: #EF9807;
            }
        }
    }
}
</style>