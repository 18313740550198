<template>
  <div class="packageList">
    <!-- 查詢 -->
    <section class="table-search">
      <div style="display: flex">
        <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
          start-placeholder="创建时间" end-placeholder="创建时间" :picker-options="pickerOptions" @change="goChangeDate()"
          :clearable="false" value-format="yyyy-MM-dd" class="mr15">
        </el-date-picker>
        <div class="search-L">
          <el-select class="mr15" v-model="tableParams.advanceIdSet" placeholder="选择推销人" clearable @change="goSelectChange()"
            multiple collapse-tags>
            <el-option v-for="item in salespersonList" :key="item.advanceId" :label="item.nickName"
              :value="item.advanceId">
            </el-option>
          </el-select>
          <el-select v-model="tableParams.shopFlag" placeholder="请选择" @change="goSelectChange" :clearable="false">
            <el-option v-for="item in ObjToOpt(shopRange)" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goClick(0)">导出</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <Table :column="rechargeColumns" :tableData="tableData">
      <template slot="performanceAmountSlot" slot-scope="scope">
        <span>{{ scope.row.performanceAmount ? '￥' + scope.row.performanceAmount : '' }}</span>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="text" size="medium" @click.stop="goClick(1, scope.row)">详情</el-button>
      </template>
    </Table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!---->
    <el-dialog title="充值业绩详情" :visible.sync="isShowDetail" :close-on-click-modal="false" append-to-body width="80%">
      <div style="display: flex;justify-content: space-between;">
        <el-date-picker v-model="detailDateToDate" type="daterange" align="center" unlink-panels range-separator="~"
          start-placeholder="创建时间" end-placeholder="创建时间" :picker-options="pickerOptions" @change="reloadDetail()"
          :clearable="false" value-format="yyyy-MM-dd" class="mr15">
        </el-date-picker>
        <el-button type="primary" @click="goClick(2)">导出</el-button>
      </div>
      <el-row :gutter="20" style="margin-top: 10px;">
        <el-col :span="5">推销人：{{ advanceInfo.nickName }}</el-col>
        <el-col :span="5">推销充值总金额：{{ advanceInfo.performanceAmount ? '￥' + advanceInfo.performanceAmount : '' }}</el-col>
        <el-col :span="5">推销充值订单数：{{ advanceInfo.performanceNum }}</el-col>
      </el-row>
      <!-- 表格 -->
      <Table :column="rechargeDetailColumns" :tableData="itemData">
        <template slot="userSlot" slot-scope="scope">
          <span>{{ scope.row.name }}｜{{ scope.row.mobile }}</span>
        </template>
        <template slot="packageAmountSlot" slot-scope="scope">
          <span>{{ scope.row.packageAmount ? '￥' + scope.row.packageAmount : '' }}</span>
        </template>
      </Table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="itemTotal"
          :page-size="itemParams.size" @size-change="onItemSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="itemCurrentPage" @current-change="onItemCurrentPage">
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import { pickerOptions,shopRange } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
import { formatterDate } from "@/utils/utils";
import { performanceList, packageDetail, salespersonList, exportPackagePage, exportPackage, detailInfo } from "@/api/serve/serve.js";
import Table from "@/components/echarts/child/table.vue";
import { rechargeColumns, rechargeDetailColumns } from "@/db/tableColumns"
export default {
  name: "packageList",
  components: {
    Table
  },
  data() {
    return {
      ObjToOpt,
      shopRange,
      rechargeColumns,
      rechargeDetailColumns,
      pickerOptions,
      formatterDate,
      tableData: [],
      total: 0,
      tableParams: {
        businessType: 4,
        page: 1,
        size: 10,
        dateFrom: "",
        dateTo: "",
        advanceIdSet: [],
        shopId: "",
        merchantId: "",
        shopFlag:1
      },
      currentPage: 1,
      isShowDetail: false,
      dateToDate: [],
      detailDateToDate: [],
      packageList: [],

      itemData: [],
      itemTotal: 0,
      itemCurrentPage: 1,
      advanceInfo: {},
      itemParams: {
        businessType: 4,
        page: 1,
        size: 10,
        dateFrom: "",
        dateTo: "",
        advanceIdSet: [],
        shopId: "",
        merchantId: "",
      
      },
      pickerMinDate: '',
      pOptions: {
        ...pickerOptions,
        disabledDate: (time) => {
          if (!this.pickerMinDate) return false; // 如果没有选择开始日期，则不禁用任何日期
          const oneYearLater = new Date(this.pickerMinDate.getFullYear() + 1, this.pickerMinDate.getMonth(), this.pickerMinDate.getDate());
          return time.getTime() > (oneYearLater.getTime() - 24 * 3600 * 1000);
        },
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate);
        },
      },
      salespersonList: [],
      shopName: ''
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
      this.itemParams.merchantId = JSON.parse(merchantShopId)[0];
      this.itemParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getMonthRange();
    this.getSalespersonList();
    this.getPerformanceList()
  },
  methods: {
    getMonthRange() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.dateToDate = [firstDay, lastDay];
    },
    // 获取商品推销人员列表
    getSalespersonList() {
      const params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      }
      salespersonList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.salespersonList = res.data;
        }
      });
    },
    // 导出业绩列表
    goExportPerformance() {
      let name =
        this.tableParams.dateFrom +
        "～" +
        this.tableParams.dateTo +
        "充值业绩";
      exportPackagePage(this.tableParams, name);
    },
    // 导出业绩详情列表
    goExportPerformanceDetail() {
      let name =
        this.itemParams.dateFrom +
        "～" +
        this.itemParams.dateTo +
        "充值业绩详情";
      exportPackage(this.itemParams, name);
    },
    // 选择推销人员
    goSelectChange() {
      this.reload();
    },
    //充值业绩列表
    getPerformanceList() {
      this.tableParams.dateFrom = this.formatterDate(this.dateToDate[0])
      this.tableParams.dateTo = this.formatterDate(this.dateToDate[1])
      performanceList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 获取头部详情数据
    getDetailInfo() {
      const params = {
        businessType: 4,
        page: this.itemParams.page,
        size: this.itemParams.size,
        dateFrom: this.itemParams.dateFrom,
        dateTo: this.itemParams.dateTo,
        advanceIdSet: [this.advanceInfo.advanceId],
        shopId: this.itemParams.shopId,
        merchantId: this.itemParams.merchantId,
        validOrderFlag: this.itemParams.validOrderFlag,
        advanceId: this.advanceInfo.advanceId,
        shopFlag: this.tableParams.shopFlag
      }
      detailInfo(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.advanceInfo.performanceAmount = res.data ? res.data.performanceAmount : '';
          this.advanceInfo.performanceNum = res.data ? res.data.performanceNum : '';
        }
      });
    },
    // 点击
    goClick(type, row) {
      switch (type) {
        case 0:
          this.goExportPerformance();
          break;
        case 1:
          this.detailDateToDate = this.dateToDate
          this.advanceInfo = JSON.parse(JSON.stringify(row));
          const merchantShopInfo = JSON.parse(window.localStorage.getItem("playOne-store-merchantShopInfo"));
          this.shopName = merchantShopInfo.shopName;
          this.itemParams.page = 1;
          this.itemCurrentPage = 1;
          this.getRechargeOrder(row.advanceId);
          this.getDetailInfo();
          this.isShowDetail = true;
          break;
        case 2:
          this.goExportPerformanceDetail();
      }
    },
    // 套餐业绩详情
    getRechargeOrder() {
      console.log(this.tableParams)
      this.itemParams.shopFlag = this.tableParams.shopFlag;
      this.itemParams.dateFrom = this.formatterDate(this.detailDateToDate[0])
      this.itemParams.dateTo = this.formatterDate(this.detailDateToDate[1])
      this.itemParams.advanceId = this.advanceInfo.advanceId;
      packageDetail(this.itemParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.itemData = res.data.list;
          this.itemTotal = res.data.total;
        }
      });
    },

    // 选择日期
    goChangeDate() {
      this.reload();
    },
    // 【监听】表格重载
    reload() {
      this.total = 0
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPerformanceList();
    },

    reloadDetail() {
      this.itemParams.page = 1;
      this.itemCurrentPage = 1;
      this.getRechargeOrder();
      this.getDetailInfo();

    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPerformanceList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.size = pageSize;
      this.reload();
    },

    // 【监听】详情表格分页点击
    onItemCurrentPage(page) {
      this.itemParams.page = page;
      this.getRechargeOrder();
    },

    // 【监听】详情表格条数点击
    onItemSizeChange(pageSize) {
      this.itemParams.size = pageSize;
      this.itemParams.page = 1;
      this.itemCurrentPage = 1;
      this.getRechargeOrder();
    },
  },
};
</script>
<style lang="scss" scoped>
.packageList {
  padding: 0.15rem;
  background: #fff;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.el-col {
  margin-bottom: 20px;
}
</style>