<template>
  <div class="room-sell">
    <div class="font-18 mb15" v-if="deadline">
      <span>{{ title}}</span>
      <span v-if="queryParams.queryType == 2">{{'（'+this.startDate+' 至 '+deadline+'）' }}</span>
    </div>
  <div class="head">
      <el-radio-group v-model="queryParams.queryType" class="mr20" @change="onTab">
        <el-radio-button v-for="item in ObjToOpt(echartDateType)" :key="item.value" :label="item.value">{{ item.label
        }}</el-radio-button>
      </el-radio-group>
      <Week @change="changeDate" v-if="queryParams.queryType == 2" />
      <el-date-picker v-else @change="changeDate" :clearable="false" value-format="yyyy-MM-dd"  format="yyyy年MM月" v-model="month" type="month" placeholder="选择月份" :picker-options="disabledDatePicker">
      </el-date-picker>
  </div>
   
    <section class="list">
      <template v-if="roomList.length > 0">
        <li class="list-item" v-for="item in roomList" :key="item.cardId">
          <div class="list-L">
            <span>{{ item.serviceName }}</span>
            <span>&nbsp;-&nbsp;</span>
            <span>{{ item.roomName }}</span>
          </div>
          <div class="list-R mr15">
            <span>￥{{ item.totalPrice || 0 }}</span>
          </div>
        </li>
      </template>
      <el-empty v-else description="暂无数据"></el-empty>
    </section>
  </div>
</template>

<script>
import { ObjToOpt, formatterDate } from "@/utils/utils";
import { echartDateType } from "@/db/objs";
import { richRoom } from "@/api/home/echart";
import Week from "@/components/echarts/child/week.vue";
export default {
  props: ['title', 'merchantShopInfo'],
  components: {
      Week
    },
  data() {
    return {
      formatterDate,
      ObjToOpt,
      echartDateType,
      queryParams: {
        merchantId: '',
        shopId: '',
        queryType:2,//2周 3月
      },
      roomList: [],
       deadline:'',
       startDate: '',
       month: '',
       disabledDatePicker: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
       

    }
  },
  created() {
    this.initMonth();
  },
  mounted() {
    // this.getRoomList();
  },
  methods: {
    initMonth() {
      const date = new Date();
      this.month = date
    },
    changeDate(date) {
            this.startDate = date
            this.getRoomList()
        },
    // 【请求】卡券销售统计
    getRoomList() {
      this.queryParams.merchantId = this.merchantShopInfo.merchantId;
      this.queryParams.shopId = this.merchantShopInfo.shopId;
      this.queryParams.startDate = this.startDate;
      richRoom(this.queryParams).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.roomList = res.data;
            this.deadline = res.data[0].deadline;
          }
        }
      });
    },
  // 切换tab
  onTab(e) {
      console.log(e)
      if(e==3){
        this.initMonth();
        let date= this.formatterDate(this.month);
        this.startDate=date.split('-')[0]+'-'+date.split('-')[1]+'-01';
      }
      this.getRoomList();
    }
  }
}
</script>


<style lang="scss" scoped>
.room-sell {
  background: #fff;
  padding: .15rem;
  width: 8.08rem;
box-sizing: border-box;
}

.head {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  margin-bottom: .15rem;
}

.font-18 {
  font-size: .18rem;
}

.total {
  font-size: .2rem;
  margin-right: .15rem;
}

.search {
  padding-bottom: .15rem;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.list {
  max-height: 3.6rem;
  overflow: auto;
  padding: .15rem;
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin: .2rem 0;
  font-size: 14px;
}

.list-L,
.list-R {
  display: flex;
  align-items: center;
  span{
    height: 100%;
    line-height: 100%;
  }
}

.date{
  width: 3.5rem;
}

.grey {
  color: rgba(144, 145, 153, 1);
}

.mr20 {
  margin-right: 0.2rem;
}
</style>