<template>
    <div class="lineChart">
        <div id="efficiency" ref="efficiency" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["chartName", "merchantShopInfo",'echartData'],
    data() {
        return {
            // 编辑弹框
            editShow: false, //弹框开关
            editTitle: "", //弹框标题
            myEchart: "",
        };
    },
    computed: {
        // 日期
        dateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.snapDay];
                })
            }
            return arr;
        },
        // 增订单用户会员转化率
        newOUCRateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.newOUCRate];
                })
            }
            return arr;
        },
        // 	门店会员平台活跃率
        smalRateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.smalRate];
                })
            }
            return arr;
        },
        // 门店会员本店活跃率
        smhalRateList() {
            let arr = [];
            if (this.echartData) {
                this.echartData.forEach((item) => {
                    arr = [...arr, item.smhalRate];
                })
            }
            return arr;
        }
    },
    methods: {
        // 【渲染】折线图
        myChartFn() {
            if (this.myEchart == "") {
                this.myEchart = echarts.init(this.$refs.efficiency);
            }
            let option = {
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.dateList,
                },
                tooltip: {
                    trigger: "axis",
                },
                color: [
                    "#EF9807",
                    "#6DBE00",
                    "#4870FF"
                ],
                yAxis: {
                    type: "value",
                    name: '百分比（%）',
                },
                legend: {
                    data: [
                        "新增订单用户会员转化率",
                        "门店会员平台活跃率",
                        "门店会员本店活跃率"
                    ],
                },
                series: [
                    {
                        name: "新增订单用户会员转化率",
                        data: this.newOUCRateList,
                        type: "line",
                        smooth: true,
                    },
                    {
                        name: "门店会员平台活跃率",
                        data: this.smalRateList,
                        type: "line",
                        smooth: true,
                    },
                    {
                        name: "门店会员本店活跃率",
                        data: this.smhalRateList,
                        type: "line",
                        smooth: true,
                    }
                ],
            };
            this.myEchart.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
.lineChart {
    width: 16rem;
    height: 3.3rem;
}
</style>