<template>
    <div class="goodsSalesList">
        <!-- 查詢 -->
        <section class="table-search">
            <div style="display: flex">
                <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
                    start-placeholder="创建时间" end-placeholder="创建时间" :clearable="false" :picker-options="pOptions"
                    @change="goChangeDate()" value-format="yyyy-MM-dd" class="mr15">
                </el-date-picker>
                <div class="search-L">
                    <el-select v-model="tableParams.advanceIdSet" placeholder="选择推销人" clearable
                        @change="goSelectChange()" multiple collapse-tags>
                        <el-option v-for="item in salespersonList" :key="item.advanceId" :label="item.nickName"
                            :value="item.advanceId">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="search-R">
                <el-button type="primary" @click="goClick(0)">导出</el-button>
            </div>
        </section>
        <!-- 表格 -->
        <Table :column="recommentGoodsColumns" :tableData="tableData">
            <template slot="paymentAmountSlot" slot-scope="scope">
                <span>{{ scope.row.paymentAmount ? '￥' + scope.row.paymentAmount : '' }}</span>
            </template>
            <template slot="refundAmountSlot" slot-scope="scope">
                <span>{{ scope.row.refundAmount ? '￥' + scope.row.refundAmount : '' }}</span>
            </template>
            <template slot="operation" slot-scope="scope">
                <el-button type="text" size="medium" @click.stop="goClick(1, scope.row)">详情</el-button>
            </template>
        </Table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
        <!---->
        <el-dialog title="推销商品业绩详情" :visible.sync="isShowDetail" :close-on-click-modal="false" append-to-body
            width="80%">
            <section class="table-search" style="margin-bottom: 10px;">
                <div style="display: flex;">
                    <div class="search-L">
                        <el-date-picker v-model="detailDateToDate" type="daterange" align="center" unlink-panels
                            range-separator="~" start-placeholder="创建时间" end-placeholder="创建时间" :clearable="false"
                            :picker-options="pOptions" @change="reloadDetail" value-format="yyyy-MM-dd" class="mr15">
                        </el-date-picker>
                    </div>
                    <el-select v-model="itemParams.validOrderFlag" placeholder="请选择" @change="reloadDetail" clearable>
                        <el-option v-for="item in ObjToOpt(goodsOrderStatus)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-R">
                    <el-button type="primary" @click="goClick(2)">导出</el-button>
                </div>
            </section>
            <el-row :gutter="15">
                <el-col :span="6">推销人：{{ advanceInfo.nickName }}</el-col>
                <el-col :span="6">查询门店：{{shopName }}</el-col>
                <el-col :span="6">实付总金额：{{ advanceInfo.paymentAmount ? '￥' + advanceInfo.paymentAmount : '' }}</el-col>
                <el-col :span="6">退款总金额：{{ advanceInfo.refundAmount ? '￥' + advanceInfo.refundAmount : '' }}</el-col>
                <el-col :span="6">总推销订单数：{{ advanceInfo.performanceNumTotal }}</el-col>
                <el-col :span="6">退款订单数：{{ advanceInfo.refundPerformanceNum }}</el-col>
                <el-col :span="6">有效订单数：{{ advanceInfo.performanceNum }}</el-col>
            </el-row>
            <!-- 表格 -->
            <Table :column="recommentGoodsDetailColumns" :tableData="itemData" :header-cell-style="headerCellStyle">
                <template slot="userInfoSlot" slot-scope="scope">
                    <span>{{ scope.row.name }}｜{{ scope.row.mobile }}</span>
                </template>
                <template slot="refundPriceSlot" slot-scope="scope">
                  <div  :class="Number(scope.row.refundPrice)>0?'color-red':''">
                    <span v-if="scope.row.refundPrice">￥</span>
                    <span>{{
                        Number(scope.row.refundPrice)>0? '-' + scope.row.refundPrice : scope.row.refundPrice
                        }}</span>
                  </div>
                </template>
                <template slot="performanceAmountSlot" slot-scope="scope">
                    <span>{{ scope.row.performanceAmount ? '￥' + scope.row.performanceAmount : '' }}</span>
                </template>
            </Table>
            <!-- 分页 -->
            <section class="table-footer">
                <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="itemTotal"
                    :page-size="itemParams.size" @size-change="onItemSizeChange" :page-sizes="[10, 20, 50, 100]"
                    :current-page.sync="itemCurrentPage" @current-change="onItemCurrentPage">
                </el-pagination>
            </section>
        </el-dialog>
    </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import { formatterDate } from "@/utils/utils";
import Table from "@/components/echarts/child/table.vue";
import { recommentGoodsColumns, recommentGoodsDetailColumns } from "@/db/tableColumns"
import { ObjToOpt } from "@/utils/utils";
import { goodsOrderStatus } from "@/db/objs";
import {
    performanceList,
    performanceDetail,
    exportPerformance,
    salespersonList,
    exportPerformanceDetail,
    detailInfo
} from "@/api/serve/serve.js";
export default {
    name: "goodsSalesList",
    components: {
        Table
    },
    data() {
        return {
            ObjToOpt,
            goodsOrderStatus,
            recommentGoodsColumns,
            recommentGoodsDetailColumns,
            formatterDate,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            tableData: [],
            total: 0,
            tableParams: {
                businessType: 3,
                page: 1,
                size: 10,
                dateFrom: "",
                dateTo: "",
                advanceIdSet: [],
                shopId: "",
                merchantId: "",
            },
            currentPage: 1,
            isShowDetail: false,
            dateToDate: [],
            detailDateToDate: [],
            itemData: [],
            itemTotal: 0,
            itemCurrentPage: 1,
            advanceInfo: {},
            itemParams: {
                businessType: 3,
                page: 1,
                size: 10,
                dateFrom: "",
                dateTo: "",
                advanceIdSet: [],
                shopId: "",
                merchantId: "",
            },
            pickerMinDate: '',
            pOptions: {
                ...pickerOptions,
                disabledDate: (time) => {
                    if (!this.pickerMinDate) return false; // 如果没有选择开始日期，则不禁用任何日期
                    const oneYearLater = new Date(this.pickerMinDate.getFullYear() + 1, this.pickerMinDate.getMonth(), this.pickerMinDate.getDate());
                    return time.getTime() > (oneYearLater.getTime() - 24 * 3600 * 1000);
                },
                onPick: obj => {
                    this.pickerMinDate = new Date(obj.minDate);
                },
            },
            salespersonList: [],
            shopName: ''
        };
    },
    created() {
        let merchantShopId = window.localStorage.getItem(
            "playOne-store-merchantShopId"
        );
        if (merchantShopId) {
            this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
            this.tableParams.shopId = JSON.parse(merchantShopId)[1];
            this.itemParams.merchantId = JSON.parse(merchantShopId)[0];
            this.itemParams.shopId = JSON.parse(merchantShopId)[1];
        }
    },
    mounted() {
        this.getMonthRange();
        this.getSalespersonList();
        this.getPerformanceList()
    },
    methods: {
        getMonthRange() {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            this.dateToDate = [firstDay, lastDay];
        },
        // 获取商品推销人员列表
        getSalespersonList() {
            const params = {
                merchantId: this.tableParams.merchantId,
                shopId: this.tableParams.shopId,
            }
            salespersonList(params).then((res) => {
                if (res.isSuccess == "yes") {
                    this.salespersonList = res.data;
                }
            });
        },
        // 导出业绩列表
        goExportPerformance() {
            let name =
                this.tableParams.dateFrom +
                "～" +
                this.tableParams.dateTo +
                "推销商品业绩";
            exportPerformance(this.tableParams, name);
        },
        // 商品推销业绩详情导出
        goExportPerformanceDetail() {
            let name =
                this.itemParams.dateFrom +
                "～" +
                this.itemParams.dateTo +
                "推销商品业绩详情";
            exportPerformanceDetail(this.itemParams, name);
        },
        // 选择推销人员
        goSelectChange() {
            this.reload();
        },
        // 推销商品业绩列表
        getPerformanceList() {
            this.tableParams.dateFrom = this.formatterDate(this.dateToDate[0])
            this.tableParams.dateTo = this.formatterDate(this.dateToDate[1])
            performanceList(this.tableParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
        // 获取头部详情数据
        getDetailInfo() {
            const params = {
                businessType: 3,
                page: this.itemParams.page,
                size: this.itemParams.size,
                dateFrom: this.itemParams.dateFrom,
                dateTo: this.itemParams.dateTo,
                advanceIdSet:[this.advanceInfo.advanceId],
                shopId:this.itemParams.shopId,
                merchantId:this.itemParams.merchantId,
                validOrderFlag:this.itemParams.validOrderFlag,
                advanceId:this.advanceInfo.advanceId
            }
            detailInfo(params).then((res) => {
                if (res.isSuccess == "yes") {
                    this.advanceInfo.paymentAmount= res.data? res.data.paymentAmount:'';
                    this.advanceInfo.refundAmount= res.data? res.data.refundAmount:'';
                    this.advanceInfo.performanceNumTotal= res.data? res.data.performanceNumTotal:'';
                    this.advanceInfo.refundPerformanceNum= res.data? res.data.refundPerformanceNum:'';
                    this.advanceInfo.performanceNum= res.data? res.data.performanceNum:'';
                }
            });
        },
        // 点击
        goClick(type, row) {
            switch (type) {
                case 0:
                    this.goExportPerformance();
                    break;
                case 1:
                    this.advanceInfo = JSON.parse(JSON.stringify(row));
                    this.detailDateToDate = this.dateToDate
                    const merchantShopInfo=JSON.parse(window.localStorage.getItem("playOne-store-merchantShopInfo"));
                    this.shopName=merchantShopInfo.shopName;
                    this.itemParams.page = 1;
                    this.itemCurrentPage = 1;
                    this.getPerformanceDetail();
                    this.getDetailInfo();
                    this.isShowDetail = true;
                    break;
                case 2:
                    this.goExportPerformanceDetail();
            }
        },
        // 商品业绩详情
        getPerformanceDetail() {
            this.itemParams.dateFrom = this.formatterDate(this.detailDateToDate[0])
            this.itemParams.dateTo = this.formatterDate(this.detailDateToDate[1])
            this.itemParams.advanceId = this.advanceInfo.advanceId;
            performanceDetail(this.itemParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.itemData = res.data.list;
                    this.itemTotal = res.data.total;
                }
            });
        },

        // 选择日期
        goChangeDate() {
            this.reload();
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getPerformanceList();
        },
        reloadDetail() {
            this.itemParams.page = 1;
            this.itemCurrentPage = 1;
            this.getPerformanceDetail();
            this.getDetailInfo();
        },

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.performanceList();
        },

        // 【监听】表格条数点击
        onSizeChange(pageSize) {
            this.tableParams.size = pageSize;
            this.reload();
        },

        // 【监听】详情表格分页点击
        onItemCurrentPage(page) {
            this.itemParams.page = page;
            this.getPerformanceDetail();
        },

        // 【监听】详情表格条数点击
        onItemSizeChange(pageSize) {
            this.itemParams.size = pageSize;
            this.itemParams.page = 1;
            this.itemCurrentPage = 1;
            this.getPerformanceDetail();
        },
    },
};
</script>
<style lang="scss" scoped>
.goodsSalesList {
    padding: 0.15rem;
    background: #fff;
}

.table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
}

.search-L {
    display: flex;
}

.el-col {
    margin-bottom: 20px;
}

.color-red {
    color: red;
}
</style>