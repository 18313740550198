<template>
    <DatePicker v-model="weekStart" type="week" ref="weekStartInput" placeholder="请选择周" value-format="yyyy-MM-dd"
        format="yyyy年WW周" @focus="weekStartFocus" @change="weekStartChange" :picker-options="startPickerOptions"
        :clearable="false" />
</template>
<script>

import { ObjToOpt, formatterDate } from "@/utils/utils";
import {
    getWeekNumber
} from 'element-ui/src/utils/date-util'
export default {
    name: 'weekPicker',
    data() {
        return {
            formatterDate,
            weekStart: '',
            weekEnd: '',
            startPickerOptions: {
                firstDayOfWeek: 1, // 设置每周的第一天为周一
                disabledDate(value) {
                    let today = new Date(); //获取今天日期
                    let d = today.getDay(); //计算今天是周几。如果是周天，d=0
                    let w = 0;
                    if (d === 0) {
                        w = 7;
                    } else {
                        w = d;
                    }
                    // let startTime = today.setDate(today.getDate() - w);
                    // return value.getTime() < startTime; //不可选本周一以前的时间
                    let endTime = today.setDate(today.getDate() + (7 - w));
                    return value.getTime() > endTime; //不可选本周日以后的时间
                },
            },

        }
    },

    created() {
        this.getMondayOfCurrentWeek()
    },
    methods: {
        getTrueDate(value) {
            let date = new Date(value);
            const week = getWeekNumber(date)
            const month = date.getMonth()
            // 判断是否是周四（ISO 周）
            const day = (date.getDay() + 6) % 7; // 周一为 0，周四为 3
            if (month == 0 && week == 1 && day == 3) {
                const revertedDate = new Date(value);
                revertedDate.setDate(revertedDate.getDate() - 3); // 反向操作减 3 天
                date = revertedDate
            }

            return this.formatterDate(date);
        },
        weekStartFocus(e) {
            this.$forceUpdate();
        },
        weekStartChange(date) {
            let trueDate = this.getTrueDate(date)
            this.$emit('change', trueDate)
        },
        // 计算天数相加后的日期
        caclDay(date, num) {
            const endWeekStart = new Date(date)//默认拿到的是结束周的周一
            const endWeekEnd = endWeekStart.setDate(endWeekStart.getDate() + num)
            return this.formatterDate(endWeekEnd)
        },
        // 获取当周周一
        getMondayOfCurrentWeek() {
            const today = new Date();
            const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
            const differenceToMonday = (dayOfWeek + 6) % 7; // Calculate the difference to Monday
            const monday = new Date(today);
            //  如果今天是周一，那么从上周一算起
            if (dayOfWeek == 1) {
                monday.setDate(monday.getDate() - 7);
            } else {
                monday.setDate(today.getDate() - differenceToMonday);
            }
            this.weekStart = this.formatterDate(monday)
            this.$emit('change', this.weekStart)
        }


    }
}
</script>
<style scoped lang="scss">
.el-popper {
    z-index: 9999 !important;

}

.el-picker-panel {
    z-index: 9999 !important;
}
</style>
