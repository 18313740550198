export const recommentGoodsColumns = [
  {
    type: "",
    label: "推销人",
    prop: "nickName",
  },
  {
    type: "",
    label: "总推销订单数",
    prop: "performanceNumTotal",
  },
  {
    type: "",
    label: "有效订单数",
    prop: "performanceNum",
  },
  {
    type: "",
    label: "退款订单数",
    prop: "refundPerformanceNum",
  },
  {
    type: "",
    label: "订单实付总金额",
    prop: "paymentAmount",
    dataType: "slot",
    slot: "paymentAmountSlot",
  },
  {
    type: "",
    label: "订单退款总金额",
    prop: "refundAmount",
    dataType: "slot",
    slot: "refundAmountSlot",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
export const recommentGoodsDetailColumns = [
  {
    type: "",
    label: "下单时间",
    prop: "addDt",
  },
  {
    type: "",
    label: "派送完成时间",
    prop: "formEndTime",
  },
  {
    type: "",
    label: "用户",
    prop: "",
    dataType: "slot",
    slot: "userInfoSlot",
  },
  {
    type: "",
    label: "商品",
    prop: "goodsInfo",
  },
  {
    type: "",
    label: "订单位置",
    prop: "position",
  },
  {
    type: "",
    label: "支付方式",
    prop: "paymentInfo",
  },

  {
    type: "",
    label: "已退款金额",
    prop: "",
    dataType: "slot",
    slot: "refundPriceSlot",
  },
  {
    type: "",
    label: "实付金额",
    prop: "",
    dataType: "slot",
    slot: "performanceAmountSlot",
  },
];
export const rechargeColumns = [
  {
    type: "",
    label: "推销人",
    prop: "nickName",
  },
  {
    type: "",
    label: "推销充值总金额",
    prop: "",
    dataType: "slot",
    slot: "performanceAmountSlot",
  },
  {
    type: "",
    label: "推销充值订单数",
    prop: "performanceNum",
  },
  {
    type: "",
    label: "操作",
    prop: "",
    dataType: "slot",
    slot: "operation",
  },
];
export const rechargeDetailColumns = [
  {
    type: "",
    label: "交易时间",
    prop: "paymentDate",
  },
  {
    type: "",
    label: "充值人",
    prop: "",
    dataType: "slot",
    slot: "userSlot",
  },
  {
    type: "",
    label: "充值套餐",
    prop: "packageName",
  },
  {
    type: "",
    label: "充值额",
    prop: "",
    dataType: "slot",
    slot: "packageAmountSlot",
  },
  {
    type: "",
    label: "套餐归属",
    prop: "packageBelongTo",
  },
  {
    type: "",
    label: "交易门店",
    prop: "shopName",
  },
];
