<template>
    <div class="baseInfo">
        <div class="title">基本信息</div>
        <div class="content">
            <Block v-for="(item, index) in labelList" :key="index" :title="item.label" :val="item.val"
                :class="(index + 1) % 4 == 0 ? '' : 'mr20'" />
        </div>
    </div>
</template>
<script>
import Block from "@/components/echarts/child/block.vue";
import { baseInfo } from "@/api/home/echart.js";
export default {
    name: 'baseInfo',
    components: {
        Block
    },
    props: ['merchantShopInfo'],
    data() {
        return {
            labelList: [
                {
                    key: 'businessArea',
                    label: '营业面积（m²）',
                    val: 0
                },
                {
                    key: 'roomCount',
                    label: '包厢总数（个）',
                    val: 0
                },
                {
                    key: 'qpRoomCount',
                    label: '棋牌包厢总数（个）',
                    val: 0
                },
                {
                    key: 'ktvRoomCount',
                    label: 'KTV包厢总数（个）',
                    val: 0
                },
                {
                    key: 'tqRoomCount',
                    label: '台球桌数（桌）',
                    val: 0
                },
                {
                    key: 'tjRoomCount',
                    label: '团建包厢总数（个）',
                    val: 0
            }],
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            const params = {
                merchantId: this.merchantShopInfo.merchantId,
                shopId: this.merchantShopInfo.shopId
            }
            baseInfo(params).then(res => {
                this.labelList.forEach(item => {
                    item.val = res.data[item.key];
                });
            })
        }
    }
}
</script>
<style scoped lang="scss">
.baseInfo {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0.21rem 0.26rem;
    box-sizing: border-box;
    margin-bottom: .15rem;

    .title {
        margin-bottom: 0.32rem;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
    }

    .mr20 {
        margin-right: 0.2rem;
    }

}
</style>